.CompetitionAdcuts {
    font-family: "Pretendard";
    font-size: 0.8rem;

    .SelectUniversityYearBox{
        display: inline-flex;
        align-items: center;
        gap: 20px;
        margin: 20px;
    }

    .SubsetWrapper{
        display: flex;
        flex-direction: column;
        gap: 10px;

        .CompetitionHeader {
            padding: 20px 20px 0;
        }

        .CompetitionBody{
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;

            background: linear-gradient(to bottom right, #f9f9f9, #e6e6e6);

            .CompetitionSubset {
                max-width: 1700px;

                .SubsetRow{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 30px;
    
                    background-color: #fff;
                    border-radius: 10px;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                    padding: 20px;

                    @media (max-width: 900px) {
                        padding: 10px;
                        gap: 10px;
                    }
                    @media (max-width: 600px) {
                        padding: 10px 5px;
                        flex-direction: column;
                    }
        
                    div{
                        scrollbar-width: auto;

                        &::-webkit-scrollbar {
                            all: initial;
                            height: .7rem;
                            width: .7rem;
                        }
                        &::-webkit-scrollbar-thumb {
                            background-color: rgb(150,150,150);
                            border-radius: 1rem;
                        }
                    } 

                    .AdcutSlider{
                        display: flex;
                        overflow-y: hidden;
                        overflow-x: auto;

                        max-width: calc(100% - 120px);

                        @media screen and (max-width: 900px) {
                            max-width: calc(100% - 75px);
                        }
                        @media screen and (max-width: 600px){
                            max-width: 100%;
                        }
                    }
        
                    .AdcutButtonBox{
                        height: 100%;

                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        gap: 30px;
                        @media (max-width: 900px) {
                            gap: 10px;
                        }
                        @media (max-width: 600px) {
                            flex-direction: row;
                            width: 100%;
                            gap: 5px;
                        }
        
                        button{
                            padding: 10px 15px;
                            width: 100%;
                            
                            border-radius: 10px;
                            background-color: #FFF;
                            cursor: pointer;
    
                            font-family: Pretendard;
                            font-size: 0.9125rem;
    
                            transition: 0.3s ease;
                            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                            color: rgb(0,0,0);
                            border: 1px solid rgb(0,0,0);

                            @media (min-width: 600px) and (max-width: 900px) {
                                font-size: 0.825rem;
                                padding: 5px;
                            }
                            @media (max-width: 600px) {
                                font-size: 0.825rem;
                                padding: 3px;
                            }
                        }
    
                        .ContentsButton{
    
                            &:hover{
                                transform: scale(1.05); 
                            }
                        }
                        .FirstEpisodeButton{
    
                            &:hover{
                                transform: scale(1.05); 
                            }
                        }
                    }
                    
                }
            }
        }

    
        .SelectUniversityYearBox{
            display: inline-flex;
            align-items: center;
            gap: 20px;
    
            margin: 20px;
        }
    }
}